
















import { Component, Vue } from 'vue-property-decorator'
import { getStyles } from '@/utils/styleFetcher'
import Logo from '@/components/Logo.vue'
@Component({
  components: { Logo },
})
export default class unAuthenticated extends Vue {
  get style() {
    return getStyles()
  }

  get title() {
    return this.style?.title ?? 'Eontyre'
  }

  get hideTitle() {
    return this.style?.navbarNoTitle ?? false
  }
}
